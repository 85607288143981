import groq from 'groq';
import {MODULE_PRODUCT} from './product';
import {PRODUCT_WITH_VARIANT} from '../productWithVariant';

export const MODULE_MARQUEE = groq`
  modules[] {
    _key,
    ${MODULE_PRODUCT}
  },
  speed,
  reverse,
  pausable
`;
