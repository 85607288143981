import groq from 'groq';

import {PORTABLE_TEXT} from '../portableText/portableText';
import {COLOR_THEME} from '../colorTheme';

export const MODULE_CONTENT = groq`
  body[]{
    ${PORTABLE_TEXT}
  },
  colorTheme->{
    ${COLOR_THEME}
  },
`;
