import {useLoaderData} from '@remix-run/react';
import {AnalyticsPageType, type SeoHandleFunction} from '@shopify/hydrogen';
import {json, type LoaderArgs} from '@shopify/remix-oxygen';
import clsx from 'clsx';

import HomeHero from '~/components/heroes/Home';
import ModuleGrid from '~/components/modules/ModuleGrid';
import {usePreviewComponent, usePreviewContext} from '~/lib/sanity';
import {SanityHeroHome, SanityHomePage} from '~/lib/sanity';
import {fetchGids, notFound, validateLocale} from '~/lib/utils';
import {HOME_PAGE_QUERY} from '~/queries/sanity/home';

const seo: SeoHandleFunction = ({data}) => ({
  title:
    data?.page?.seo?.title.en ||
    'STAB - Premium Streetwear - Sneaker and Clothing Shop • stickabush.com',
  description:
    data?.page?.seo?.description.en ||
    'Order your favorite sneakers and clothing from our Streetwear Online Store Berlin. We offer a wide selection of top brands like Adidas, Carhartt WIP for all gender and fast worldwide shipping.',
});

export const handle = {
  seo,
};

export async function loader({context, params}: LoaderArgs) {
  validateLocale({context, params});

  const cache = context.storefront.CacheCustom({
    mode: 'public',
    maxAge: 60,
    staleWhileRevalidate: 60,
  });

  const page = await context.sanity.query<SanityHomePage>({
    query: HOME_PAGE_QUERY,
    cache,
  });

  if (!page) {
    throw notFound();
  }

  // Resolve any references to products on the Storefront API
  const gids = await fetchGids({page, context});

  return json({
    page,
    gids,
    analytics: {
      pageType: AnalyticsPageType.home,
    },
  });
}

export default function Index() {
  const {page} = useLoaderData<typeof loader>();
  const Component = usePreviewComponent<{page: SanityHomePage}>(Route, Preview);

  return <Component page={page} />;
}

function Route({page}: {page: SanityHomePage}) {
  const bgClass = '';

  return (
    <div className={`contentModule ${bgClass}`}>
      {/* Page hero */}
      {page?.hero && <HomeHero hero={page.hero as SanityHeroHome} />}

      {page?.modules && (
        <div className={clsx('mb-22 mt-12 px-0', 'md:px-0')}>
          <ModuleGrid items={page.modules} />
        </div>
      )}
    </div>
  );
}

function Preview(props: {page: SanityHomePage}) {
  const {usePreview} = usePreviewContext()!;
  const page = usePreview(HOME_PAGE_QUERY, undefined, props.page);

  return <Route page={page} />;
}
