import clsx from 'clsx';

import {useFetcher, useLocation, useMatches} from '@remix-run/react';
import LinkButton from '~/components/elements/LinkButton';
import HeroContent from '~/components/heroes/HeroContent';
import type {SanityHeroHome} from '~/lib/sanity';

import {DEFAULT_LOCALE} from '~/lib/utils';

type Props = {
  hero: SanityHeroHome;
};

export default function HomeHero({hero}: Props) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  const lang = selectedLocale.language;

  let title = '';
  if (lang === 'DE') {
    title = hero.title?.de;
  }
  if (lang === 'EN') {
    title = hero.title?.en;
  }

  let landscapeA = hero.landscape;
  let landscapeB = hero.landscape2;

  let layout = 'default';

  if (landscapeA === true) {
    layout = 'leftWide';
  }
  if (landscapeB === true) {
    layout = 'rightWide';
  }

  if (landscapeA === true && landscapeB === true) {
    layout = 'default';
  }

  return (
    <div>
      <div className={clsx('relative mt-16 pt-2', 'md:mt-28 md:pt-0')}>
        <div className="relative">
          {hero.content2 !== null && hero.content2 !== undefined ? (
            <div className="w-full">
              <div className={clsx('w-full', 'w-full')}>
                {layout === 'default' ? (
                  <div
                    className={clsx(
                      'grid w-full grid-cols-1 gap-0',
                      'gap-2 lg:grid-cols-[50%_50%]',
                    )}
                  >
                    {/* Hero content */}
                    {hero.content && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content} layout={50} />
                      </div>
                    )}

                    {/* Hero content 2 */}
                    {hero.content2 && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content2} layout={50} />
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {layout === 'rightWide' ? (
                  <div
                    className={clsx(
                      'grid w-full grid-cols-1 gap-0',
                      'gap-2 lg:grid-cols-[30%_70%]',
                    )}
                  >
                    {hero.content && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content} layout={30} />
                      </div>
                    )}

                    {hero.content2 && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content2} layout={70} />
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {layout === 'leftWide' ? (
                  <div
                    className={clsx(
                      'grid w-full grid-cols-1 gap-0',
                      'gap-2 lg:grid-cols-[70%_30%]',
                    )}
                  >
                    {hero.content && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content} layout={70} />
                      </div>
                    )}

                    {hero.content2 && (
                      <div className="mt-0 min-h-full w-full">
                        <HeroContent content={hero.content2} layout={30} />
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              {/* Hero content */}
              {hero.content && (
                <div
                  className={clsx(
                    'mt-0 w-full', //
                    'md:mt-0',
                  )}
                >
                  <HeroContent content={hero.content} layout={100} />
                </div>
              )}
            </>
          )}
        </div>
        <div className={clsx('absolute top-[15px]')}>
          {title && (
            <h1 className="mb-6 max-w-[50rem] px-4 pt-2 text-3xl md:px-8 md:pt-6 md:text-4xl">
              {title}
            </h1>
          )}
          {hero.link && (
            <>
              <LinkButton link={hero.link} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
